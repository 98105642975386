import "./App.css";
import { Routes, Route } from "react-router-dom";
import Footer from "./components/Footer.tsx";
import Header from "./components/header/Header.tsx";
import OurStory from "./pages/OurStory.tsx";
import HowItWorks from "./pages/HowItWorks.tsx";
import ShareAReview from "./pages/ShareAReview.tsx";
import Resources from "./pages/Resources.tsx";
import Blog from "./components/blog/Blog.tsx";
import Login from "./components/user/Login.tsx";
import Contact from "./pages/Contact.tsx";
import ForgotPassword from "./components/user/ForgotPassword.tsx";
import ResetPassword from "./components/user/ResetPassword.tsx";
import ResetPasswordSuccess from "./components/user/ResetPasswordSuccess.tsx";
import CreateAccount from "./components/user/CreateAccount.tsx";
import MyAccount from "./pages/MyAccount.tsx";
import Home from "./pages/Home.tsx";
import SearchResults from "./pages/SearchResults.tsx";
import Community from "./pages/Community.tsx";
import SeniorAdvisor from "./pages/Advisors.tsx";
import FAQ from "./components/faq/Faq.tsx";
import AdminLogin from "./pages/Admin.tsx";
import NotFound from "./pages/NotFound.tsx";
import ManageAdvisors from "./components/advisorList/ManageAdvisors.tsx";
import PersistLogin from "./components/user/PersistLogin.tsx";
import Unauthorized from "./pages/Unauthorized.tsx";
import FindAnAdvisor from "./components/advisor/FindAnAdvisor.tsx";
import AgentRegister from "./components/advisorAccount/Register.tsx";
import LayoutWithSearchBar from "./pages/LayoutWithSearchBar.tsx";
import LayoutWithOutSearchBar from "./pages/LayoutWithoutSearchBar.tsx";
import FreeServices from "./pages/FreeServices.tsx";
import {
  InitialMeeting,
  ComplimentaryAssessments,
  MoveInAssistance,
  FreeGuidedTours,
} from "./components/utils/stepsForServices/FreeServicesPages.tsx";

import SeniorLivingGuide from "./pages/SeniorLivingGuide.tsx";
import LegalAndFinancial from "./pages/LegalAndFinancialPlanning.tsx";
import TypesOfCare from "./pages/TypesOfCare.tsx";

import RegisterCareChampion from "./components/careChampion/Register.tsx";

import SeniorAdvisorRegister from "./components/advisorAccount/SeniorAdvisorRegister.tsx";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LayoutWithSearchBar />}>
          {/* PUblic routes */}
          <Route path="/" element={<Home />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/search-results" element={<SearchResults />} />
          <Route path="/faq" element={<FAQ />} />
        </Route>
        {/* Pages */}
        <Route path="/" element={<LayoutWithOutSearchBar />}>
          <Route path="/our-story" element={<OurStory />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/share-a-review" element={<ShareAReview />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/find-an-advisor" element={<FindAnAdvisor />} />
          <Route path="free-services" element={<FreeServices />} />
          <Route path="/community/:dynamicRoute" element={<Community />} />
          {/* Advisor */}
          <Route path="/agent-register" element={<AgentRegister />} />
          <Route
            path="/senior-advisor/:advisorId"
            element={<SeniorAdvisor />}
          />
          <Route path="/senior-living-guide" element={<SeniorLivingGuide />} />
          <Route path="/legal-and-financial" element={<LegalAndFinancial />} />
          <Route path="/types-of-care" element={<TypesOfCare />} />
          <Route path="/blog" element={<Blog />} />

          {/* free services */}
          <Route path="/initial-meeting" element={<InitialMeeting />} />
          <Route
            path="/complimentary-assessments"
            element={<ComplimentaryAssessments />}
          />
          <Route path="/move-in-assistance" element={<MoveInAssistance />} />
          <Route path="/free-guided-tours" element={<FreeGuidedTours />} />
        </Route>

        {/* user & admin */}
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/admin/manage-advisors" element={<ManageAdvisors />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/reset-password-success"
          element={<ResetPasswordSuccess />}
        />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/my-account" element={<MyAccount />} />

        {/* Care Champion login & register */}
        <Route
          path="/care-champion-register"
          element={<RegisterCareChampion />}
        />

        {/* Senior Advisor register */}
        <Route
          path="/senior-advisor-register"
          element={<SeniorAdvisorRegister />}
        />

        {/* Persist Login */}

        {/* Catch-all route for 404 Not Found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
