import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo-footer.png";

const Footer = () => {
  return (
    <footer className="bg-primary text-white py-8">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row justify-between">
          <div>
            <div>
              <div className="text-lg font-bold my-2">Quick Links</div>
              <ul className="flex flex-wrap">
                <li>
                  <Link
                    to="/"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Home"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/our-story"
                    className="mr-4 hover:text-gray-300"
                    aria-label="About Us and Our Story"
                  >
                    Our Story
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Contact Us"
                  >
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/faq"
                    className="mr-4 hover:text-gray-300"
                    aria-label="FAQs"
                  >
                    FAQs
                  </Link>
                </li>
                <li>
                  <Link
                    to="/search-results"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Browse Communities"
                  >
                    Browse Communities
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <div className="text-lg font-bold my-2 mt-6">Resources</div>
              <ul className="flex flex-wrap">
                <li>
                  <Link
                    to="/senior-living-guide"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Senior Living Guide"
                  >
                    Senior Living Guide
                  </Link>
                </li>
                <li>
                  <Link
                    to="/find-an-advisor"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Real Estate Resources"
                  >
                    Real Estate Resources
                  </Link>
                </li>
                <li>
                  <Link
                    to="/legal-and-financial"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Legal & Financial Planning"
                  >
                    Legal & Financial Planning
                  </Link>
                </li>
                <li>
                  <Link
                    to="/blog"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Blog"
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    to="/types-of-care"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Types of Care"
                  >
                    Types of Care
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <div className="text-lg font-bold my-2 mt-6">
                Tools & Interactive Features
              </div>
              <ul className="flex flex-wrap">
                <li>
                  <Link
                    to="/find-a-senior-advisor"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Find a Senior Advisor"
                  >
                    Find a Senior Advisor
                  </Link>
                </li>
                <li>
                  <Link
                    to="/claim-a-community"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Claim a Community"
                  >
                    Claim a Community
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <div className="text-lg font-bold my-2 mt-6">
                Account Management
              </div>
              <ul className="flex flex-wrap">
                <li>
                  <Link
                    to="/sign-in"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Sign In / Register"
                  >
                    Sign In / Register
                  </Link>
                </li>
                <li>
                  <Link
                    to="/user-dashboard"
                    className="mr-4 hover:text-gray-300"
                    aria-label="User Dashboard"
                  >
                    User Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to="/senior-advisor-login"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Senior Advisor Login"
                  >
                    Senior Advisor Login
                  </Link>
                </li>
                <li>
                  <Link
                    to="/manage-account"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Manage Account"
                  >
                    Manage Account
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex w-full md:w-1/4">
            <div>
              <div className="flex items-center">
                <img
                  src={logo}
                  alt="bluejay logo"
                  className="h-20 logo__footer rounded my-7"
                />
                <Link
                  className="block bg-action p-3 border-1 h-12 ml-5 font-bold border-action rounded text-action-100 mr-4 hover:bg-tertiary-500 hover:text-accent-100"
                  to="/contact"
                  aria-label="Contact Us"
                >
                  Contact Us
                </Link>
              </div>
              <p className="footer-serving-la font-bold">
                Serving Los Angeles County and the Surrounding Areas
              </p>
            </div>
          </div>
        </div>
        <div className="mt-8 footer-disclosure text-sm">
          <p>
            Information provided on this site is for informational purposes only
            and may not reflect the most current community listings,
            availability, or pricing; please verify details directly with
            individual communities or agents. The content on this site does not
            constitute legal, financial, or medical advice; consult with a
            licensed professional for guidance specific to your needs. Real
            estate agents and senior advisors featured on Blue Jay are
            independent contractors, not employees of BlueJay®, and are not
            supervised or managed by us. BlueJay® is committed to providing
            equal opportunities and does not discriminate based on race, color,
            religion, sex, national origin, disability, or age. We prioritize
            your privacy, and any personal information collected on this site is
            used in accordance with our Privacy Policy and is not shared without
            your consent. © 2025 BlueJay®. All rights reserved. Unauthorized use
            or reproduction of site content is strictly prohibited.
          </p>
          <p className=" text-right">
            &copy; {new Date().getFullYear()} Bluejay. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
