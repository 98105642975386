import React from "react";

interface Unit {
  number: string;
  rate: string;
  availability: string;
  info: string;
}

interface PricingTableProps {
  units: Unit[];
}

const PricingTable: React.FC<PricingTableProps> = ({ units }) => (
  <table className="w-full border-collapse">
    <thead>
      <tr>
        <th className="border p-2">Unit</th>
        <th className="border p-2">Monthly Rate</th>
        <th className="border p-2">Availability</th>
        <th className="border p-2">Information</th>
      </tr>
    </thead>
    <tbody>
      {units.map((unit, index) => (
        <tr key={index}>
          <td className="border p-2">{unit.number}</td>
          <td className="border p-2">{unit.rate}</td>
          <td className="border p-2">{unit.availability}</td>
          <td className="border p-2">{unit.info}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default PricingTable;
