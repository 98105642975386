import React from "react";
import BannerImg from "../utils/BannerImg";
import bannerImg from "../../assets/banners/banner.png";
import heroTextImg from "../../assets/heroTextImg1.png";

const Register: React.FC = () => {
  return (
    <div>
      <h1 className="text-3xl text-right w-full md:w-1/2 md:border-b-4 border-black font-bold my-4">
        Become a Bluejay Senior Advisor Partner
      </h1>
      <BannerImg imgUrl={bannerImg} />
      <HeroText />
      <PageText />
      <Quote />
      <AgentProfileManagement />
    </div>
  );
};

export default Register;

const HeroText: React.FC = () => {
  return (
    <div className="w-full border-b-4 border-black mb-7">
      <div className="hero-text container mx-auto flex flex-col md:flex-row justify-between items-center mb-6">
        <div className="w-full md:w-1/2 mb-4 md:mb-0">
          <h2 className="text-xl md:text-2xl font-bold my-2 text-center md:text-left">
            A staggering 83% of people seeking adult care must sell their homes.
          </h2>
          <p className="text-sm md:text-md my-2 text-center md:text-left">
            Source: The United States Census Bureau 2020 US Census Bureau's
            American Community Survey (ACS) 2019 Federal Reserve Bank National
            Investment Center for Seniors Housing & Care (NIC), as of 2020
          </p>
        </div>
        <div className="w-full md:w-auto flex justify-center">
          <img
            src={heroTextImg}
            alt="seniors seeking adult care selling their homes"
            className="max-w-xs md:max-w-sm"
          />
        </div>
      </div>
    </div>
  );
};

const PageText: React.FC = () => {
  return (
    <div className="w-full border-b-4 border-black mb-7">
      <div className="container mx-auto p-6 bg-white shadow-lg rounded-lg mb-7">
        <h1 className="text-3xl font-bold text-black-500 mb-4">
          Hello and welcome!
        </h1>
        <p className="mb-4">
          We’re excited to introduce you to a new model for generating quality
          home seller leads.
        </p>
        <p className="mb-6">
          <span className="font-semibold text-blue-500">BlueJay®</span> presents
          a fantastic opportunity to establish a new income stream by assisting
          home sellers transitioning from their homes into senior care
          communities.
        </p>
        <ul className="list-disc pl-6 space-y-4 text-gray-700">
          <li>
            <strong className="">Discovery Call:</strong> Start with a discovery
            call or a meeting with the potential sellers or their adult
            children. Understand their needs and guide them to a suitable senior
            community. If it's beneficial, you could accompany them to the
            community.
          </li>
          <li>
            <strong className="">Become Part of Their Care Team:</strong> Aim to
            integrate yourself into their care team. Nurture the lead within
            your CRM and maintain ongoing communication with clients and their
            families.
          </li>
          <li>
            <strong className="">Build Your Database:</strong> Use this
            opportunity to expand your database of potential sellers. Remember,
            80% of those moving into memory care, independent living, or
            assisted living are selling their homes immediately or within a few
            years.
          </li>
          <li>
            <strong className="">Provide Comprehensive Service:</strong>{" "}
            Consider this service an extension of your current offerings. Just
            as you coordinate with handymen, skilled laborers, attorneys, and
            gardeners, go the extra mile by being a guiding light towards senior
            communities.
          </li>
          <li>
            <strong className="">Leverage Your Network:</strong> Connect them
            with local vendors and resources you already work with. You aim to
            make the process smooth and stress-free, positioning yourself as a
            trusted partner in their transition.
          </li>
          <li>
            <strong className="">Simple and Fun Signup:</strong> We’ve made the
            signup process straightforward and engaging. Expect a steady flow of
            potential leads.
          </li>
        </ul>
        <p className="mt-6 text-gray-700 font-semibold">
          Best of luck! Embrace this opportunity and exceed expectations.
        </p>
      </div>
    </div>
  );
};

const Quote: React.FC = () => {
  return (
    <div className="w-full border-b-4 border-black mb-7">
      <div className="container mx-auto p-6  text-2xl font-semibold text-black">
        “The man who does more than he is paid for will soon be paid for more
        than he does.” — <strong>Napoleon Hill</strong>
      </div>
    </div>
  );
};

const AgentProfileManagement = () => (
  <div className="container mx-auto p-6 space-y-8 text-gray-800 bg-white shadow-lg rounded-lg mb-7">
    <h2 className="text-2xl font-bold text-black-600">
      Agent Profile Management
    </h2>

    <section>
      <h3 className="text-xl font-semibold">
        Enhance Your Professional Visibility
      </h3>
      <p className="mt-2">
        Maximize your market presence on{" "}
        <span className="font-semibold">Bluejay</span> by managing and uploading
        your professional profile. This comprehensive portal allows you to
        showcase your credentials, including your real estate license number,
        certifications, biography, and detailed experience. Agents can easily
        sign up to create an account and begin crafting a profile designed to
        attract potential clients.
      </p>
    </section>

    <section>
      <h3 className="text-xl font-semibold text-black-500">
        Introductory Offer During Beta Testing
      </h3>
      <p className="mt-2">
        During our beta phase, agents can enjoy all the basic features of
        profile management without any subscription fees.
      </p>
    </section>

    <section>
      <h3 className="text-xl font-bold">Subscription Plans</h3>

      <div className="mt-4">
        <div className="border border-gray-200 rounded-lg p-4 mb-4 bg-gray-50">
          <h4 className="text-lg font-semibold text-blue-600">
            Basic Plan: <span className="text-gray-700">FREE</span>
          </h4>
          <p className="mt-2 text-gray-600">
            Start building your presence with our no-cost Basic Plan. Create and
            display your profile on the "Find an Advisor" page, where potential
            home sellers can discover you through your engaging headshot and
            detailed biography, outlining your credentials and expertise.
          </p>
          <button className="mt-4 px-4 py-2 bg-green-600 text-white font-semibold rounded-md hover:bg-green-700 transition duration-300">
            Make a Free Account
          </button>
        </div>

        <div className="border border-gray-200 rounded-lg p-4 mb-4 bg-blue-50">
          <h4 className="text-lg font-semibold text-blue-800">
            Premier Advisors Plan:{" "}
            <span className="text-gray-700">$249/month</span>
          </h4>
          <p className="mt-2 text-gray-600">
            Elevate your game with the Premier Advisors Plan. Your profile will
            not only appear on the "Find an Advisor" page but will also be
            dynamically rotated as the point of contact on senior community
            profiles. This plan increases your visibility and positions you
            directly in front of potential clients browsing specific community
            pages.
          </p>
          <button className="mt-4 px-4 py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition duration-300">
            Go Premier
          </button>
        </div>

        <div className="border border-gray-200 rounded-lg p-4 bg-yellow-50">
          <h4 className="text-lg font-semibold text-yellow-800">
            Elite Advisors Plan:{" "}
            <span className="text-gray-700">$499/month</span>
          </h4>
          <p className="mt-2 text-gray-600">
            Achieve top-tier status with the Elite Advisors Plan. Designed for
            high-performing agents, this plan places you as a primary point of
            contact on senior community profiles with both rotating and static
            placements. Additionally, your profile will be prominently featured
            on various banners and website pages beyond just the community
            search results. This plan includes multiple calls to action,
            designed to generate and capture leads in a trackable format.
          </p>
          <button className="mt-4 px-4 py-2 bg-yellow-600 text-white font-semibold rounded-md hover:bg-yellow-700 transition duration-300">
            Go Elite
          </button>
        </div>
      </div>
    </section>

    <section>
      <h3 className="text-xl font-semibold">
        Enhance Your Career with Bluejay
      </h3>
      <p className="mt-2">
        Each subscription plan is tailored to boost your visibility and
        engagement with potential clients. As Bluejay grows, we are committed to
        enhancing these offerings to best meet the needs of our agents and the
        communities they serve. Join us in revolutionizing the way senior living
        is explored and chosen.
      </p>
    </section>
  </div>
);
