// AdvisorsGrid.js
import React, { useState } from "react";
import SingleAdvisor from "./SingleAdvisor";
import GridAdvisors from "./MultipleAdvisors";
import { advisorData } from "./AdvisorData";

interface AdvisorsGridProps {
  initialShowSingleAdvisor: boolean;
}

const AdvisorsGrid: React.FC<AdvisorsGridProps> = ({
  initialShowSingleAdvisor,
}) => {
  // State to control showing more advisors and current advisor
  const [showMore, setShowMore] = useState(false);
  const [showSingleAdvisor, setShowSingleAdvisor] = useState(
    initialShowSingleAdvisor
  );
  const [currentAdvisorIndex, setCurrentAdvisorIndex] = useState(0);

  // Split headshots based on 'showMore' state
  const visibleHeadshots = showMore ? advisorData : advisorData.slice(0, 12);

  // Show one advisor based on current index
  const currentAdvisor = advisorData[currentAdvisorIndex];

  // Handle previous and next for single advisor
  const handlePrevious = () => {
    setCurrentAdvisorIndex(
      (prevIndex) => (prevIndex - 1 + advisorData.length) % advisorData.length
    );
  };

  const handleNext = () => {
    setCurrentAdvisorIndex((prevIndex) => (prevIndex + 1) % advisorData.length);
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-3xl font-bold my-6 text-center">Our Advisors</h2>

      {/* Toggle View Buttons */}
      {/* <div className="text-center mb-6">
        <button
          onClick={() => setShowSingleAdvisor(!showSingleAdvisor)}
          className="px-6 py-3 bg-primary text-white rounded-lg font-semibold hover:bg-secondary transition duration-300 mr-4"
        >
          {showSingleAdvisor ? "View Grid" : "View One Advisor"}
        </button>
      </div> */}

      {/* Show one advisor or grid */}
      {showSingleAdvisor ? (
        <SingleAdvisor
          advisor={currentAdvisor}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
        />
      ) : (
        <GridAdvisors
          advisors={visibleHeadshots}
          showMore={showMore}
          toggleShowMore={() => setShowMore(!showMore)}
        />
      )}
    </div>
  );
};

export default AdvisorsGrid;
