import React from "react";
import { useForm } from "react-hook-form";
import { RegistrationFormData } from "./RegistrationFormData";

interface SeniorAdvisorRegisterFormPageProps {
  register: any;
  handleSubmit: any;
  errors: any;
}

const SeniorAdvisorRegisterFormPage6: React.FC<
  SeniorAdvisorRegisterFormPageProps
> = ({ register, handleSubmit, errors }) => {
  return (
    <>
      <h2 className="text-xl font-semibold mb-4">Go Live</h2>

      <div className="mb-4">
        <p className="block text-gray-700">
          <strong>Review and Submit:</strong> Double-check your profile for
          accuracy and completeness. Hit ‘submit’ to send your details for
          approval.
        </p>
        <p className="block text-gray-700">
          <strong>Approval Process:</strong> Once approved, your profile will go
          live, and you’ll officially be part of a pioneering group of advisors
          who are redefining senior living.
        </p>
      </div>
    </>
  );
};
export default SeniorAdvisorRegisterFormPage6;
