import React from "react";
import { useForm } from "react-hook-form";
import { RegistrationFormData } from "./RegistrationFormData";

interface SeniorAdvisorRegisterFormPageProps {
  register: any;
  handleSubmit: any;
  errors: any;
}

const SeniorAdvisorRegisterFormPage4: React.FC<
  SeniorAdvisorRegisterFormPageProps
> = ({ register, handleSubmit, errors }) => {
  return (
    <>
      <h2 className="text-xl font-semibold mb-4">
        Build Trust with Testimonials
      </h2>

      <div className="mb-4">
        <label className="block text-gray-700">
          <strong>Client Testimonials:</strong> Include positive feedback from
          previous clients. This social proof is invaluable, showcasing your
          ability to empathize with and solve the challenges faced by seniors.
        </label>
        <input
          type="text"
          className="form-input mt-1 block w-full"
          {...register("feedback", {
            required: "This is required.",
            minLength: { value: 2, message: "Minimum length is 2." },
          })}
        />
        {errors.feedback && (
          <p className="text-red-500">{errors.feedback?.message}</p>
        )}
      </div>
    </>
  );
};
export default SeniorAdvisorRegisterFormPage4;
