import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBlog, faTools, faBook } from "@fortawesome/free-solid-svg-icons"; // Importing Font Awesome icons

const Menu: React.FC = () => {
  const [isSelected, setIsSelected] = useState<number | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);

  const menuItems = [
    { to: "/", label: "Home", index: 0 },
    { to: "/our-story", label: "Our Story", index: 1 },
    { to: "/how-it-works", label: "How It Works", index: 2 },
    { to: "/free-services", label: "Free Services", index: 3 },
    { to: "/share-a-review", label: "Share A Review", index: 4 },
    {
      to: "/resources",
      label: "Resources",
      index: 5,
      dropdown: [
        { to: "/blog", label: "Blogs", icon: faBlog }, // Added icon for Blogs
        { to: "/guides", label: "Guides", icon: faBook }, // Added icon for Guides
        { to: "/tools", label: "Tools", icon: faTools }, // Added icon for Tools
      ],
    },
    {
      to: "/find-an-advisor",
      label: "Find An Advisor",
      index: 6,
    },
  ];

  const location = useLocation();

  const handleLinkClick = (index: number) => {
    setIsSelected(index);
  };

  const handleMouseEnter = (index: number) => {
    setDropdownOpen(index);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(null);
  };

  return (
    <div className="justify-end md:flex-grow md:flex md:items-center">
      <div className="mx-auto">
        {menuItems.map((item) => (
          <div
            key={item.index}
            onMouseEnter={() => handleMouseEnter(item.index)}
            onMouseLeave={handleMouseLeave}
            className="relative inline-block"
          >
            <Link
              to={item.to}
              onClick={() => handleLinkClick(item.index)}
              className={`block font-bold mt-4 md:inline-block md:mt-0 mr-4 hover:underline 
                ${
                  isSelected === item.index || location.pathname === item.to
                    ? "text-primary"
                    : "text-accent"
                }`}
              aria-label={item.label}
            >
              {item.label}
            </Link>

            {/* Dropdown Menu */}
            {item.dropdown && dropdownOpen === item.index && (
              <div
                className="absolute left-0 mt-0 w-48 bg-white border rounded shadow-lg z-10"
                onMouseEnter={() => handleMouseEnter(item.index)}
                onMouseLeave={handleMouseLeave} // Allow hovering into dropdown
              >
                {item.dropdown.map((subItem, subIndex) => (
                  <Link
                    key={subIndex}
                    to={subItem.to}
                    className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                  >
                    <FontAwesomeIcon
                      icon={subItem.icon}
                      className="w-5 h-5 text-gray-600 mr-2"
                    />
                    {subItem.label}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Menu;
