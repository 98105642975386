import React from "react";

interface AdvisorInfoProps {
  name: string;
  title: string;
  imageUrl: string;
}

const AdvisorInfo: React.FC<AdvisorInfoProps> = ({ name, title, imageUrl }) => (
  <div className="flex flex-col items-center space-x-4">
    <img src={imageUrl} alt={name} className="w-24 h-24" />
    <div>
      <p className="text-lg font-bold">{name}</p>
      <p className="text-sm text-secondary">{title}</p>
    </div>
  </div>
);

export default AdvisorInfo;
