import React, { useState, useEffect } from "react";
import AdvisorLocationFilter from "./AdvisorLocationFilter.tsx"; // Adjust the import path as necessary
import AdvisorList from "./AdvisorList.tsx"; // Adjust the import path as necessary
import Breadcrumbs from "../Breadcrumbs.tsx";
import axios from "axios";
import { Advisor } from "../../types/types.ts";

const advisors: Advisor[] = [
  {
    name: "John Doe",
    location: "New York",
    areasServed: "Manhattan, Brooklyn",
  },
  {
    name: "Jane Smith",
    location: "Los Angeles",
    areasServed: "Hollywood, Beverly Hills",
  },
  {
    name: "Emily Johnson",
    location: "Chicago",
    areasServed: "Downtown, Lincoln Park",
  },
  {
    name: "John Doe",
    location: "New York",
    areasServed: "Manhattan, Brooklyn",
  },
  {
    name: "Jane Smith",
    location: "Los Angeles",
    areasServed: "Hollywood, Beverly Hills",
  },
  {
    name: "Emily Johnson",
    location: "Chicago",
    areasServed: "Downtown, Lincoln Park",
  },
];

const ManageAdvisors: React.FC = () => {
  //   const [advisors, setAdvisors] = useState<Advisor[]>([]);
  const [filteredAdvisors, setFilteredAdvisors] = useState<Advisor[]>([
    {
      name: "John Doe",
      location: "New York",
      areasServed: "Manhattan, Brooklyn",
    },
    {
      name: "Jane Smith",
      location: "Los Angeles",
      areasServed: "Hollywood, Beverly Hills",
    },
    {
      name: "Emily Johnson",
      location: "Chicago",
      areasServed: "Downtown, Lincoln Park",
    },
    {
      name: "John Doe",
      location: "New York",
      areasServed: "Manhattan, Brooklyn",
    },
    {
      name: "Jane Smith",
      location: "Los Angeles",
      areasServed: "Hollywood, Beverly Hills",
    },
    {
      name: "Emily Johnson",
      location: "Chicago",
      areasServed: "Downtown, Lincoln Park",
    },
  ]);

  //   useEffect(() => {
  //     // Fetch the list of senior advisors
  //     axios
  //       .get("https://ultranick.com/api/agents")
  //       .then((response) => {
  //         setAdvisors(response.data);
  //         setFilteredAdvisors(response.data); // Initialize filtered advisors
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching advisors:", error);
  //       });
  //   }, []);

  const handleFilter = (filteredAdvisors: Advisor[]) => {
    setFilteredAdvisors(filteredAdvisors);
  };

  return (
    <div className="container mt-3">
      <Breadcrumbs
        items={[
          { label: "Home", path: "/" },
          { label: "Admin", path: "/admin" },
          { label: "Manage Advisors", path: "/admin/manage-advisors" },
        ]}
      />
      <h1 className="text-3xl font-bold mb-4">Manage Advisors</h1>
      <AdvisorLocationFilter advisors={advisors} onFilter={handleFilter} />
      <AdvisorList advisors={filteredAdvisors} />
    </div>
  );
};

export default ManageAdvisors;
