import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const SearchBar: React.FC = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [categoryQuery, setCategoryQuery] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleCategoryInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCategoryQuery(e.target.value);
  };

  const handleSearch = () => {
    // Implement your search logic here
    console.log("Searching for:", query);
    setCategoryQuery("");
    setQuery("");
    navigate("/search-results");
  };

  return (
    <>
      <h2 className="text-6xl mt-4 text-primary text-center m-4 search-bar-heading tracking-tight">
        Find Senior Living Near You
      </h2>
      <div className="relative my-10 items-center border border-black rounded-3xl w-2/6 hidden md:flex mx-auto">
        <input
          className="appearance-none bg-transparent border-none  w-full text-accent  py-1 px-2 leading-tight focus:outline-none"
          type="text"
          placeholder="Memory Care, Assisted Living, etc."
          aria-label="Search"
          value={categoryQuery}
          onChange={handleCategoryInputChange}
        />
        <span
          className="flex items-center justify-center text-black"
          style={{
            opacity: "90%",
            fontSize: "2.5rem",
            fontWeight: "100",
            position: "absolute",
            left: "45%",
            bottom: "1px",
          }}
        >
          <svg
            width="2"
            height="66"
            viewBox="0 0 2 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1"
              y1="0"
              x2="1"
              y2="44"
              stroke="currentColor"
              strokeWidth="0.7"
            />
          </svg>
        </span>
        <input
          className="appearance-none bg-transparent border-none  w-full text-accent  py-1 px-2 leading-tight focus:outline-none"
          type="text"
          placeholder="Location"
          aria-label="Search"
          value={query}
          onChange={handleInputChange}
        />
        <button
          className="flex-shrink-0 bg-action hover:bg-tertiary border-action hover:border-secondary text-sm border-4 text-accent py-2 px-3 rounded"
          type="button"
          aria-label="Search button"
          onClick={handleSearch}
        >
          <span
            aria-hidden="true"
            role="img"
            className="icon--24-search-v2 y-css-1qhatk2"
          >
            <svg
              width="24"
              height="24"
              className="text-accent group-hover:text-secondary"
            >
              <path d="m21.853 20.355-3.444-3.443a9.428 9.428 0 1 0-16.761-6.171 9.428 9.428 0 0 0 15.348 7.586l3.443 3.442a1 1 0 1 0 1.414-1.414ZM5.82 16.245a7.429 7.429 0 1 1 5.253 2.175 7.38 7.38 0 0 1-5.253-2.176Z"></path>
            </svg>
          </span>
        </button>
      </div>
    </>
  );
};

export default SearchBar;
