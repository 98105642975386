import React from "react";
import CategoryFilter from "./CategoryFilter.tsx";
import PriceFilter from "./PriceFilter.tsx";

const FilterComponent: React.FC = () => {
  return (
    <div className="md:w-1/4">
      <PriceFilter />
      <CategoryFilter />
    </div>
  );
};

export default FilterComponent;
