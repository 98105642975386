import React, { useState } from "react";

import carousel1 from "../assets/carousel-1.png";
import carousel2 from "../assets/carousel-2.png";
import carousel3 from "../assets/carousel-3.png";

const images = [carousel1, carousel2, carousel3];

const descriptions = [
  "A beautiful nature scene in the forest.",
  "away.",
  "- Jimmy Buffet",
];

const headings = [
  "Together we get you there.",
  "New beginnnings are a click",
  "Wrinkles will only go where the smiles have been.",
];

const VerticalCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToImage = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="flex flex-col items-center  h-screen space-y-4">
      <div className="relative w-full  overflow-hidden rounded-lg shadow-lg">
        <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex}`}
          className="w-full h-full object-contain transition-transform duration-500 ease-in-out"
        />

        {/* Paragraph Box */}
        {/* <div className="absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4 bg-white bg-opacity-75 p-4 rounded-lg shadow-lg w-3/4 text-center">
          <h2 className="text-xl font-medium text-gray-500">
            {headings[currentIndex]}
          </h2>
          <p className="text-lg font-medium text-gray-800">
            {descriptions[currentIndex]}
          </p>
        </div> */}
        {/* Dots Indicator */}
        <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2">
          {images.map((_, index) => (
            <button
              key={index}
              onClick={() => goToImage(index)}
              aria-label="Go to image button"
              className={`w-4 h-4 rounded-full ${
                index === currentIndex ? "bg-gray-700" : "bg-gray-400"
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default VerticalCarousel;
