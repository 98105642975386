import React from "react";

interface SectionHeaderProps {
  title: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ title }) => (
  <h2 className="text-2xl font-bold text-primary bg-secondary">{title}</h2>
);

export default SectionHeader;
