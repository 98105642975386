import React, { useState } from "react";
import { categories } from "./FilterOptions.ts";

const CategoryFilter: React.FC = () => {
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);

  const handleSelect = (id: number) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((categoryId) => categoryId !== id)
        : [...prevSelected, id]
    );
  };

  return (
    <div className="category-filter p-4">
      <h2 className="text-lg font-semibold mb-2">Categories</h2>
      <ul className="space-y-2">
        {categories.map((category) => (
          <li key={category.id}>
            <button
              aria-label={`${category.name} category filter`}
              className={`px-4 py-2 rounded ${
                selectedCategories.includes(category.id)
                  ? "bg-primary text-secondary"
                  : "bg-secondary text-accent"
              }`}
              onClick={() => handleSelect(category.id)}
            >
              {category.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryFilter;
