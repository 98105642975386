import React, { useState } from "react";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return "Please enter a value for Email";
    } else if (!emailRegex.test(email)) {
      return "Please enter a valid email address";
    } else if (email.length > 255) {
      return "Email cannot exceed 255 characters";
    }
    return "";
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setEmailError(validateEmail(newEmail));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const emailValidationError = validateEmail(email);

    setEmailError(emailValidationError);

    if (!emailValidationError) {
      // Proceed with forgot password logic (e.g., API call)
      console.log("Form is valid. Sending reset password email...");
      // Display success message
      setSuccessMessage(
        "Sent! You should receive an email shortly containing instructions to reset your password."
      );
      // Simulate API call
      setTimeout(() => {
        // Reset form
        setEmail("");
        setEmailError("");
      }, 2000);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center text-primary">
          Forgot Password
        </h2>
        <p className="text-sm text-center text-primary">
          Enter your email address to reset your password
        </p>
        {successMessage && (
          <p className="text-center text-green-600">{successMessage}</p>
        )}
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-primary"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              className="w-full px-3 py-2 mt-1 border rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
              required
            />
            {emailError && (
              <p className="mt-1 text-sm text-error">{emailError}</p>
            )}
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2 font-bold text-white bg-primary rounded-md hover:bg-secondary focus:outline-none focus:ring focus:ring-indigo-200"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
