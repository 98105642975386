import React from "react";

interface CommunityDetailsProps {
  id: number;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  stateLicense: string;
  description: string;
  latitude: string;
  longitude: string;
  website: string;
  rating: number;
  ratingCount: number;
  active: boolean;
}

const CommunityDetails: React.FC<CommunityDetailsProps> = ({
  id,
  name,
  address1,
  address2,
  city,
  state,
  zip,
  phone,
  stateLicense,
  description,
  latitude,
  longitude,
  website,
  rating,
  ratingCount,
  active,
}) => {
  return (
    <div className="flex flex-col md:flex-row community-details p-4 space-y-4 md:space-y-0 md:space-x-4">
      {/* Community Image */}
      <a href={website} className="w-1/4">
        <img
          src={`https://via.placeholder.com/400?text=${name}`}
          alt="Community"
          className="w-full  h-auto rounded"
        />
      </a>

      <div className="flex flex-col m-4">
        {/* Community Title */}
        <a
          href={website}
          className="text-xl font-bold text-primary hover:underline mb-2"
        >
          {name}
        </a>

        {/* Community Address */}
        <p className="text-primary mb-2">
          {address1}, {address2}, {city}, {state}, {zip}
        </p>

        {/* Community Contact */}
        <p className="text-primary mb-2">Phone: {phone}</p>

        {/* State License */}
        <p className="text-primary mb-2">State License: {stateLicense}</p>

        {/* Community Description */}
        {/* <p className="text-primary mb-2">{description}</p> */}

        {/* Community Rating */}
        <p className="text-primary mb-2">
          Rating: {rating} ({ratingCount} reviews)
        </p>

        {/* Community Link */}
        <a href={website} className="text-primary hover:underline mb-3">
          Visit Website
        </a>
      </div>
    </div>
  );
};

export default CommunityDetails;
