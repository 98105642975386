import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  validateNewPassword,
  validateConfirmPassword,
} from "../validation/Validation.tsx";
import {
  validatePasswordStrength,
  getPasswordStrengthColor,
} from "../validation/ValPwdStrength.tsx";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");

  const navigate = useNavigate();

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
    setNewPasswordError(validateNewPassword(newPassword));
    setPasswordStrength(validatePasswordStrength(newPassword));
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
    setConfirmPasswordError(
      validateConfirmPassword(newPassword, confirmPassword)
    );
  };

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    const newPasswordValidationError = validateNewPassword(newPassword);
    const confirmPasswordValidationError = validateConfirmPassword(
      newPassword,
      confirmPassword
    );

    setNewPasswordError(newPasswordValidationError);
    setConfirmPasswordError(confirmPasswordValidationError);

    if (!newPasswordValidationError && !confirmPasswordValidationError) {
      console.log("Form is valid. Updating password...");
      navigate("/reset-password-success");
    }
  };

  return (
    <div className="max-w-xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold text-center text-primary mb-4">
        My Account
      </h1>
      <div className="mb-4">
        <label className="block text-secondary">Name</label>
        <p className="text-secondary">John Doe</p>
      </div>
      <div className="mb-4">
        <label className="block text-secondary">Old Password</label>
        <input
          type="password"
          className="w-full px-3 py-2 border rounded-lg"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
      </div>

      <form className="space-y-4" onSubmit={handleSave}>
        <div>
          <label
            htmlFor="newPassword"
            className="block text-sm font-medium text-secondary"
          >
            New Password
          </label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={handleNewPasswordChange}
            className="w-full px-3 py-2 mt-1 border rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
            required
          />
          {newPasswordError && (
            <p className="mt-1 text-sm text-error">{newPasswordError}</p>
          )}
          <p
            className={`mt-1 text-sm ${getPasswordStrengthColor(
              passwordStrength
            )}`}
          >
            Password strength: {passwordStrength}
          </p>
        </div>
        <div>
          <label
            htmlFor="confirmPassword"
            className="block text-sm font-medium text-secondary"
          >
            Confirm New Password
          </label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            className="w-full px-3 py-2 mt-1 border rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
            required
          />
          {confirmPasswordError && (
            <p className="mt-1 text-sm text-error">{confirmPasswordError}</p>
          )}
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 font-bold text-white bg-primary rounded-md hover:bg-secondary focus:outline-none focus:ring focus:ring-indigo-200"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;
