import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ResetPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");

  const navigate = useNavigate();

  const validatePasswordStrength = (password: string) => {
    if (password.length < 8) {
      return "Weak";
    } else if (password.length < 12) {
      return "Mediocre";
    } else {
      return "Strong";
    }
  };

  const getPasswordStrengthColor = (strength: string) => {
    switch (strength) {
      case "Weak":
        return "text-error";
      case "Mediocre":
        return "text-yellow-600";
      case "Strong":
        return "text-green-600";
      default:
        return "";
    }
  };

  const validateNewPassword = (password: string) => {
    if (!password) {
      return "Please enter a value for New Password";
    } else if (password.length < 8) {
      return "Password must be at least 8 characters";
    } else if (validatePasswordStrength(password) === "Weak") {
      return "Password is not strong enough";
    }
    return "";
  };

  const validateConfirmPassword = (
    password: string,
    confirmPassword: string
  ) => {
    if (!confirmPassword) {
      return "Please confirm your new password";
    } else if (password !== confirmPassword) {
      return "Passwords do not match";
    }
    return "";
  };

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
    setNewPasswordError(validateNewPassword(newPassword));
    setPasswordStrength(validatePasswordStrength(newPassword));
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
    setConfirmPasswordError(
      validateConfirmPassword(newPassword, confirmPassword)
    );
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newPasswordValidationError = validateNewPassword(newPassword);
    const confirmPasswordValidationError = validateConfirmPassword(
      newPassword,
      confirmPassword
    );

    setNewPasswordError(newPasswordValidationError);
    setConfirmPasswordError(confirmPasswordValidationError);

    if (!newPasswordValidationError && !confirmPasswordValidationError) {
      console.log("Form is valid. Updating password...");
      navigate("/reset-password-success");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center text-primary">
          Reset Password
        </h2>
        <p className="text-sm text-center text-secondary">
          Enter your new password below
        </p>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="newPassword"
              className="block text-sm font-medium text-secondary"
            >
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={handleNewPasswordChange}
              className="w-full px-3 py-2 mt-1 border rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
              required
            />
            {newPasswordError && (
              <p className="mt-1 text-sm text-error">{newPasswordError}</p>
            )}
            <p
              className={`mt-1 text-sm ${getPasswordStrengthColor(
                passwordStrength
              )}`}
            >
              Password strength: {passwordStrength}
            </p>
          </div>
          <div>
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium text-secondary"
            >
              Confirm New Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              className="w-full px-3 py-2 mt-1 border rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
              required
            />
            {confirmPasswordError && (
              <p className="mt-1 text-sm text-error">{confirmPasswordError}</p>
            )}
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2 font-bold text-white bg-primary rounded-md hover:bg-secondary focus:outline-none focus:ring focus:ring-indigo-200"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
