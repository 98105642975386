import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { RegistrationFormData } from "./RegistrationFormData";
import SeniorAdvisorRegisterFormPage1 from "./SeniorAdvisorRegisterFormPage1";
import SeniorAdvisorRegisterFormPage2 from "./SeniorAdvisorRegisterFormPage2";
import SeniorAdvisorRegisterFormPage3 from "./SeniorAdvisorRegisterFormPage3";
import SeniorAdvisorRegisterFormPage4 from "./SeniorAdvisorRegisterFormPage4";
import SeniorAdvisorRegisterFormPage5 from "./SeniorAdvisorRegisterFormPage5";
import SeniorAdvisorRegisterFormPage6 from "./SeniorAdvisorRegisterFormPage6";

const SeniorAdvisorRegister: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState<RegistrationFormData>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    dob: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegistrationFormData>();

  const onSubmit = (data: RegistrationFormData) => {
    // Handle form submission, e.g., send data to the server
    console.log(data);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <>
      <div className="registration-container flex flex-col justify-center items-center mx-auto p-4 min-h-screen">
        <div className="hidden md:flex md:flex-col items-center container">
          <h1 className="text-2xl font-bold mb-10">
            Senior Advisor Registration
          </h1>

          {/* Step Indicator */}
          <div className="w-full mb-10">
            <div className="flex justify-between items-center pl-10">
              {[1, 2, 3, 4, 5, 6].map((step) => (
                <div
                  key={step}
                  className="flex-1 relative text-center font-bold"
                >
                  <div
                    className={`w-10 h-10 rounded-md flex items-center justify-center 
                  ${step === currentPage ? "bg-primary" : "bg-gray-300"} 
                  transition-colors duration-300`}
                  >
                    {step}
                  </div>
                  {step < 6 && (
                    <div
                      className={`h-0.5 w-full absolute top-5 left-10
                    ${step < currentPage ? "bg-primary" : "bg-gray-200"}
                    transition-colors duration-300`}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full h-full bg-white p-6 rounded shadow-md min-h-[500px] flex flex-col">
          {/* Render the form fields based on the current page */}
          {currentPage === 1 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <SeniorAdvisorRegisterFormPage1
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
              />
            </form>
          )}
          {currentPage === 2 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <SeniorAdvisorRegisterFormPage2
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
              />
            </form>
          )}
          {currentPage === 3 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <SeniorAdvisorRegisterFormPage3
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
              />
            </form>
          )}
          {currentPage === 4 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <SeniorAdvisorRegisterFormPage4
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
              />
            </form>
          )}
          {currentPage === 5 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <SeniorAdvisorRegisterFormPage5
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
              />
            </form>
          )}
          {currentPage === 6 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <SeniorAdvisorRegisterFormPage6
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
              />
            </form>
          )}

          {/* Button Container */}
          <div className="flex justify-end mt-auto">
            {currentPage > 1 && (
              <button
                type="button"
                onClick={handlePreviousPage}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
              >
                Previous
              </button>
            )}
            {currentPage < 6 && (
              <button
                type="button"
                onClick={handleNextPage}
                className="bg-primary text-white px-4 py-2 rounded"
              >
                Next
              </button>
            )}

            {currentPage === 6 && (
              <button
                type="submit"
                form="registration-form"
                className="bg-primary text-white px-4 py-2 rounded"
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SeniorAdvisorRegister;
