import React, { useEffect, useRef, useState } from "react";
import { MapProps } from "../../types/types";
import { callback } from "./util";
// Extend the Window interface to include Microsoft
declare global {
  interface Window {
    Microsoft: typeof Microsoft;
    bingMapsCallback: () => void;
  }
}

const Map: React.FC<MapProps> = ({ property, nearbyProperties }) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<Microsoft.Maps.Map | null>(null);
  const [pushpins, setPushpins] = useState<Microsoft.Maps.Pushpin[]>([]);
  const apiKey = process.env.REACT_APP_BING_MAPS_API_KEY;
  const scriptLoadedRef = useRef(false);

  useEffect(() => {
    if (scriptLoadedRef.current) return;

    const existingScript = document.querySelector(
      `script[src*="bing.com/api/maps/mapcontrol"]`
    );

    if (existingScript) {
      if (window.Microsoft && window.Microsoft.Maps) {
        callback(window, mapRef, apiKey!, property, setMap);
      } else {
        existingScript.addEventListener("load", () =>
          callback(window, mapRef, apiKey!, property, setMap)
        );
      }
    } else {
      loadBingMapsScript(apiKey!, () =>
        callback(window, mapRef, apiKey!, property, setMap)
      );
    }

    scriptLoadedRef.current = true;
  }, [apiKey, property]);

  // Second useEffect: Add pushpins and event listeners to the map
  useEffect(() => {
    if (map) {
      // Set the default view with a zoomed-out level
      map.setView({
        center: new window.Microsoft.Maps.Location(
          property.latitude,
          property.longitude
        ),
        zoom: 5, // Adjust the zoom level as needed (lower value for more zoomed out)
      });
      // Add pushpin for the main property
      const mainPushpin = new window.Microsoft.Maps.Pushpin(
        new window.Microsoft.Maps.Location(
          property.latitude,
          property.longitude
        ),
        { title: property.name }
      );
      map.entities.push(mainPushpin);
      setPushpins((prevPushpins) => [...prevPushpins, mainPushpin]);

      // Add pushpins for nearby properties
      nearbyProperties.forEach((nearbyProperty) => {
        const pushpin = new window.Microsoft.Maps.Pushpin(
          new window.Microsoft.Maps.Location(
            nearbyProperty.latitude,
            nearbyProperty.longitude
          ),
          { title: nearbyProperty.name }
        );
        map.entities.push(pushpin);
        setPushpins((prevPushpins) => [...prevPushpins, pushpin]);
        console.log("Pushpin added", pushpins);

        // Add click event listener to the pushpin
        window.Microsoft.Maps.Events.addHandler(pushpin, "click", () => {
          const infobox = new window.Microsoft.Maps.Infobox(
            pushpin.getLocation(),
            {
              title: nearbyProperty.name,
              description: `${nearbyProperty.summary} <br/><a href="/community/${nearbyProperty.id}">View Details</a>`,
              visible: true,
            }
          );
          infobox.setMap(map);
        });
      });

      // Add click event listener to the main pushpin
      window.Microsoft.Maps.Events.addHandler(mainPushpin, "click", () => {
        const infobox = new window.Microsoft.Maps.Infobox(
          mainPushpin.getLocation(),
          {
            title: property.name,
            description: `${property.summary} <br/><a href="/community/${property.id}">View Details</a>`,
            visible: true,
          }
        );
        infobox.setMap(map);
      });
    }
  }, [map, property, nearbyProperties]);

  return (
    <div>
      <div ref={mapRef} style={{ width: "100%", height: "400px" }} />
    </div>
  );
};

export default Map;

function loadBingMapsScript(apiKey: string, callback: () => void) {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.defer = true;
  script.src = `https://www.bing.com/api/maps/mapcontrol?callback=bingMapsCallback&key=${apiKey}`;
  document.body.appendChild(script);

  window.bingMapsCallback = callback;
}
