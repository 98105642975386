import React from "react";

const CredentialsList = ({ credentials }) => (
  <div className="mb-4">
    <h3 className="text-lg font-semibold my-2">Credentials</h3>
    <ul className="list-disc list-inside">
      {credentials.map((credential, index) => (
        <li key={index}>{credential}</li>
      ))}
    </ul>
  </div>
);

export default CredentialsList;
