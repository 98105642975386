import React from "react";

interface HeroImageProps {
  src: string;
  alt: string;
}

const HeroImage: React.FC<HeroImageProps> = ({ src, alt }) => (
  <div className="relative">
    <img src={src} alt={alt} className="w-full h-auto" />
  </div>
);

export default HeroImage;
