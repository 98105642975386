import React from "react";

interface BottomBorderProps {
  width: string;
  right: boolean;
}

const BottomBorder: React.FC<BottomBorderProps> = ({ width, right }) => {
  // Use a regular expression to extract the numerator and denominator
  const match = width.match(/(\d+)\/(\d+)/);
  if (!match) {
    throw new Error("Invalid fraction format");
  }
  const numerator = parseInt(match[1]);
  const denominator = parseInt(match[2]);

  // Calculate the remaining width as a fraction
  const remainingNumerator = denominator - numerator;
  const remainingDenominator = denominator;
  const remainingWidth = `${remainingNumerator}/${remainingDenominator}`;

  if (right) {
    return (
      <>
        <div className={`w-${remainingWidth} inline-block`}></div>
        <div
          className={`inline-block border-b-4 border-black w-${width} mb-5`}
        ></div>
      </>
    );
  }

  return (
    <>
      <div
        className={`inline-block border-b-4 border-black w-${width} mb-5`}
      ></div>
      <div className={`w-${remainingWidth} inline-block`}></div>
    </>
  );
};
export default BottomBorder;
