import React from "react";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  itemsPerPage: number;
  onPageChange: (pageNumber: number) => void;
  onItemsPerPageChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  itemsPerPage,
  onPageChange,
  onItemsPerPageChange,
}) => {
  const pageNumbers = [];
  const maxPageNumbersToShow = 5; // Adjust this number to show more or fewer page numbers
  const halfPageNumbersToShow = Math.floor(maxPageNumbersToShow / 2);

  let startPage = Math.max(1, currentPage - halfPageNumbersToShow);
  let endPage = Math.min(totalPages, currentPage + halfPageNumbersToShow);

  if (currentPage - halfPageNumbersToShow <= 0) {
    endPage = Math.min(
      totalPages,
      endPage + (halfPageNumbersToShow - currentPage + 1)
    );
  }

  if (currentPage + halfPageNumbersToShow > totalPages) {
    startPage = Math.max(
      1,
      startPage - (currentPage + halfPageNumbersToShow - totalPages)
    );
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex justify-center items-center mt-4">
      <div className="flex mr-4 mb-4">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="mx-1 px-3 py-1 border rounded bg-accent text-primary hover:cursor-pointer"
        >
          &lt;
        </button>
        {startPage > 1 && (
          <>
            <button
              onClick={() => onPageChange(1)}
              className={`mx-1 px-3 py-1 border rounded ${
                1 === currentPage
                  ? "bg-secondary text-accent"
                  : "bg-accent text-secondary"
              }`}
            >
              1
            </button>
            {startPage > 2 && <span className="mx-1 px-3 py-1">...</span>}
          </>
        )}
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => onPageChange(number)}
            className={`mx-1 px-3 py-1 border rounded ${
              currentPage === number
                ? "bg-secondary text-accent"
                : "bg-accent text-secondary"
            }`}
          >
            {number}
          </button>
        ))}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && (
              <span className="mx-1 px-3 py-1">...</span>
            )}
            <button
              onClick={() => onPageChange(totalPages)}
              className={`mx-1 px-3 py-1 border rounded ${
                totalPages === currentPage
                  ? "bg-secondary text-accent"
                  : "bg-accent text-secondary"
              }`}
            >
              {totalPages}
            </button>
          </>
        )}
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="mx-1 px-3 py-1 border rounded bg-accent text-primary hover:cursor-pointer"
        >
          &gt;
        </button>
      </div>
      <div className="flex items-center w-1/3 mb-4">
        <label
          htmlFor="itemsPerPage"
          className="block mr-2 text-sm font-medium text-gray-700"
        >
          Items per page:
        </label>
        <select
          id="itemsPerPage"
          value={itemsPerPage}
          onChange={onItemsPerPageChange}
          className="mt-1 block w-2/12 pl-3 pr-3 py-2 text-base border-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
        </select>
      </div>
    </div>
  );
};

export default Pagination;
