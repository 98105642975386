import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/header/Header.tsx";
import SearchBar from "../components/Searchbar.tsx";

const LayoutWithSearchBar = () => {
  return (
    <>
      <Header />
      <SearchBar />
      <Outlet />
    </>
  );
};

export default LayoutWithSearchBar;
