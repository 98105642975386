import React, { useState } from "react";

interface Advisor {
  name: string;
  location: string;
  areasServed: string;
}

interface AdvisorLocationFilterProps {
  advisors: Advisor[];
  onFilter: (filteredAdvisors: Advisor[]) => void;
}

const AdvisorLocationFilter: React.FC<AdvisorLocationFilterProps> = ({
  advisors,
  onFilter,
}) => {
  const [selectedLocation, setSelectedLocation] = useState("");

  const handleLocationChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const location = event.target.value;
    setSelectedLocation(location);
    if (location === "") {
      onFilter(advisors); // Show all advisors when "All Locations" is selected
    } else {
      const filteredAdvisors = advisors.filter(
        (advisor) => advisor.location === location
      );
      onFilter(filteredAdvisors);
    }
  };

  const uniqueLocations = Array.from(
    new Set(advisors.map((advisor) => advisor.location))
  );

  return (
    <div className="mb-4 w-1/3">
      <label htmlFor="location" className="block text-xl text-secondary">
        Filter by Location
      </label>
      <select
        id="location"
        value={selectedLocation}
        onChange={handleLocationChange}
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-2 focus:outline-none focus:ring-indigo-500 focus:border-secondary sm:text-sm rounded-md"
      >
        <option value="">All Locations</option>
        {uniqueLocations.map((location, index) => (
          <option key={index} value={location}>
            {location}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AdvisorLocationFilter;
