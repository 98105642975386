import React from "react";

interface SectionContentProps {
  content: string;
}

const SectionContent: React.FC<SectionContentProps> = ({ content }) => (
  <div className="text-lg text-secondary">{content}</div>
);

export default SectionContent;
