import React from "react";

interface SeniorAdvisorRegisterFormPageProps {
  register: any;
  handleSubmit: any;
  errors: any;
}

const SeniorAdvisorRegisterFormPage5: React.FC<
  SeniorAdvisorRegisterFormPageProps
> = ({ register, handleSubmit, errors }) => {
  return (
    <>
      <h2 className="text-xl font-semibold mb-4">Showcase Your Success</h2>

      <div className="mb-4">
        <label className="block text-gray-700">
          <strong>Visual Proof:</strong> Upload before-and-after images of
          properties you’ve worked on, illustrating your ability to transform
          spaces into homes suited for senior living.
        </label>
        <input
          type="file"
          className="form-input mt-1 block w-full"
          {...register("proof", {
            required: "This is required.",
          })}
        />
        {errors.proof && (
          <p className="text-red-500">{errors.proof?.message}</p>
        )}
      </div>
    </>
  );
};
export default SeniorAdvisorRegisterFormPage5;
