import React, { useState, useEffect } from "react";
import PersonalDetails from "../components/sidebar/PersonalDetails.tsx";
import KeepInTouch from "../components/sidebar/KeepInTouch.tsx";
import SideBarMenu from "../components/header/SideBarMenu.tsx";
import ChangePassword from "../components/sidebar/ChangePassword.tsx";
import AddAddresses from "../components/sidebar/AddAddresses.tsx";
import { useParams } from "react-router-dom";
import axiosInstance from "../components/api/axiosInstance.tsx";

const MyAccount = () => {
  const { userId } = useParams<{ userId: string }>();
  const [activeComponent, setActiveComponent] =
    useState<string>("personal-details");

  const [userData, setUserData] = useState<any>(null);
  console.log(userData);
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get(`/agents`);
        setUserData(response.data);
        console.log("user data", response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [userId]);

  const renderComponent = () => {
    switch (activeComponent) {
      case "change-password":
        return <ChangePassword />;
      case "addresses":
        return <AddAddresses />;
      case "personal-details":
        return (
          <>
            <PersonalDetails /> <KeepInTouch />
          </>
        );
      default:
        return <div>Select an option from the sidebar</div>;
    }
  };

  return (
    <div className="flex flex-col md:flex-row justify-evenly min-h-screen bg-accent mt-10">
      <SideBarMenu
        setActiveComponent={setActiveComponent}
        activeComponent={activeComponent}
      />
      <div className="w-full max-w-3xl p-8 space-y-6 bg-white rounded-lg shadow-md">
        <h1 className="text-2xl font-bold text-center text-primary">
          My Account - John Doe
        </h1>
        <div className="items-center justify-center min-h-[80vh] bg-gray-100 p-6">
          {renderComponent()}
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
