import React from "react";

const NotFound = () => {
  return (
    <div className="container min-h-screen flex flex-col items-center justify-center m-4">
      <h1 className="text-2xl font-bold">404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;
