import React, { useState } from "react";

const KeepInTouch = () => {
  const [optIn, setOptIn] = useState(true);

  const handleChange = (e: any) => {
    setOptIn(e.target.value === "true");
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Save the opt-in/opt-out preference
    alert("Saved!");
  };

  return (
    <div className="w-full max-w-md p-4 space-y-6 bg-white rounded-lg shadow-md mx-auto mt-5">
      <h2 className="text-2xl font-bold text-center text-primary">
        Keep in Touch
      </h2>
      <p className="text-sm text-gray-600">
        Please opt in for emails so that we can contact you about additional
        resources available to you and keep you up-to-date with news from
        BlueJay. You can unsubscribe any time. Please refer to our{" "}
        <a className="text-primary hover:underline" href="/">
          Privacy Policy
        </a>{" "}
        or{" "}
        <a className="text-primary hover:underline" href="/">
          Contact Us
        </a>{" "}
        for more details.
      </p>
      <form className="space-y-4" onSubmit={handleSubmit}>
        <div className="flex items-center space-x-4">
          <label className="flex items-center space-x-2">
            <input
              type="radio"
              name="optIn"
              value="true"
              checked={optIn === true}
              onChange={handleChange}
              className="form-radio text-primary focus:ring focus:ring-indigo-200"
            />
            <span className="text-secondary">Opt In</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="radio"
              name="optIn"
              value="false"
              checked={optIn === false}
              onChange={handleChange}
              className="form-radio text-primary focus:ring focus:ring-indigo-200"
            />
            <span className="text-secondary">Opt Out</span>
          </label>
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 font-bold text-accent bg-primary rounded-md hover:bg-secondary focus:outline-none focus:ring focus:ring-indigo-200"
        >
          Save
        </button>
      </form>
    </div>
  );
};

export default KeepInTouch;
