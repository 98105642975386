import React from "react";
import img from "../../assets/blogImg.png";

const Blog: React.FC = () => {
  return (
    <div className="w-full flex mb-10">
      <div className="w-2/6">
        <h1 className="text-3xl font-bold my-7 text-right pb-4 w-full border-b-4 border-black">
          Welcome to the Bluejay Blog
        </h1>
        <img src={img} alt="banner for bluejay" />
      </div>
      <div className="w-4/6 flex flex-col p-6 md:p-36">
        <h2 className="text-4xl font-bold ">
          Share Your Experiences & Reviews
        </h2>
        <p className="text-lg mb-3">
          We encourage our community to leave reviews and share their
          experiences touring local communities and working with our Senior
          Advisors. The stakes are high, and by sharing our experiences, we can
          support and uplift each other. Let's champion the best options
          together!
        </p>
        <button className="w-banner mt-28 px-4 py-2 bg-green-600 text-white font-semibold rounded-md hover:bg-green-700 transition duration-300">
          Share
        </button>
      </div>
    </div>
  );
};

export default Blog;
