import React from "react";

interface RequestTourButtonProps {
  onClick: () => void;
}

const RequestTourButton: React.FC<RequestTourButtonProps> = ({ onClick }) => (
  <button className="bg-primary text-white py-2 px-4 rounded" onClick={onClick}>
    Request a Tour
  </button>
);

export default RequestTourButton;
