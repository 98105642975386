import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoImage from "../../assets/logo.png";
import Menu from "../Menu.tsx";
import SecondMenu from "./SecondMenu.tsx";

const Header: React.FC = () => {
  const isAuthorized = (roles: number[]) => {
    return roles.includes(2001);
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-secondary sticky top-0 z-10">
      {isAuthorized([2001, 1984, 5150]) && (
        <button
          onClick={toggleNavbar}
          className="text-accent md:hidden"
          aria-label="home logo button"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      )}
      {isAuthorized([2001, 1984, 5150]) && (
        <div
          className={`${
            isOpen ? "block" : "hidden"
          }  w-full md:flex md:items-center md:w-auto`}
        >
          <div className="container bg-secondary flex p-5 w-full">
            <div
              className={`items-center  ${
                isAuthorized([2001, 1984, 5150])
                  ? ""
                  : "flex-col justify-center"
              }`}
            >
              <div className="container flex items-center relative justify-between">
                {logoImage ? (
                  <>
                    <Link
                      to="/"
                      className="text-white flex"
                      aria-label="home logo"
                    >
                      <img
                        src={logoImage}
                        alt="logo"
                        className="h-24 w-24"
                        style={{ cursor: "pointer" }}
                      />
                    </Link>
                  </>
                ) : (
                  <h2 className="text-2xl text-accent font-semibold ml-4 self-center">
                    BlueJay
                  </h2>
                )}
              </div>
            </div>
            <Menu />
            <SecondMenu />
          </div>
        </div>
      )}
      {/* Undecided on where to place SearchBar */}
      {/* <SearchBar /> */}
    </nav>
  );
};

export default Header;
