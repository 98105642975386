import { MutableRefObject } from "react";

export function callback(
  window: Window & typeof globalThis,
  mapRef: MutableRefObject<HTMLDivElement | null>,
  apiKey: string,
  property: { latitude: number; longitude: number },
  setMap: (map: Microsoft.Maps.Map) => void
) {
  if (window.Microsoft && window.Microsoft.Maps && mapRef.current) {
    const newMap = new window.Microsoft.Maps.Map(mapRef.current, {
      credentials: apiKey,
      center: new window.Microsoft.Maps.Location(
        property.latitude,
        property.longitude
      ),
      zoom: 14,
    });

    setMap(newMap);
  }
}

export const removePushpin = (
  map: Microsoft.Maps.Map | null,
  pushpin: Microsoft.Maps.Pushpin,
  setPushpins: React.Dispatch<React.SetStateAction<Microsoft.Maps.Pushpin[]>>
) => {
  if (map) {
    map.entities.remove(pushpin);
    setPushpins((prevPushpins) => prevPushpins.filter((p) => p !== pushpin));
  }
};

export const renamePushpin = (
  pushpin: Microsoft.Maps.Pushpin,
  newName: string
) => {
  pushpin.setOptions({ title: newName });
};
