import React from "react";

interface SeniorAdvisorRegisterFormPageProps {
  register: any;
  handleSubmit: any;
  errors: any;
}

const SeniorAdvisorRegisterFormPage2: React.FC<
  SeniorAdvisorRegisterFormPageProps
> = ({ register, handleSubmit, errors }) => {
  return (
    <>
      <h2 className="text-xl font-semibold mb-4">Detail Your Expertise</h2>

      <div className="mb-4">
        <label className="block text-gray-700">
          <strong>Professional Background:</strong> Share your story. Why did
          you choose this path? What experiences have shaped your approach to
          senior real estate?
        </label>
        <textarea
          type="text"
          className="form-input mt-1 block w-full"
          {...register("background", {
            required: "This is required.",
            minLength: { value: 2, message: "Minimum length is 2." },
          })}
        />
        {errors.background && (
          <p className="text-red-500">{errors.background?.message}</p>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">
          <strong>Specializations and Certifications:</strong> Highlight your
          qualifications, especially those relevant to senior living, such as
          SRES (Senior Real Estate Specialist) or CAPS (Certified Aging-in-Place
          Specialist).
        </label>
        <textarea
          type="text"
          className="form-input mt-1 block w-full"
          {...register("certifications", {
            required: "This is required.",
            minLength: { value: 2, message: "Minimum length is 2." },
          })}
        />
        {errors.certifications && (
          <p className="text-red-500">{errors.certifications?.message}</p>
        )}
      </div>
    </>
  );
};
export default SeniorAdvisorRegisterFormPage2;
