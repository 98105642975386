import React from "react";
import { useParams } from "react-router-dom";
import Photo from "./Photo.tsx";
import Name from "./Name.tsx";
import Title from "./Title.tsx";
import LicenseNumber from "./LicenseNumber.tsx";
import DirectPhone from "./DirectPhone.tsx";
import CellPhone from "./CellPhone.tsx";
import EmailButton from "./EmailButton.tsx";
import CredentialsList from "./CredentialsList.tsx";
import ServicesList from "./ServicesList.tsx";
import AwardsRecognitions from "./AwardsRecognitions.tsx";
import ClientTestimonials from "./ClientTestimonials.tsx";
import YearsInBusiness from "./YearsInBusiness.tsx";
import AreasServed from "./AreasServed.tsx";
import FollowMeLinks from "./FollowMeLinks.tsx";
import Summary from "./Summary.tsx";

const SeniorAdvisor = ({ advisor }) => {
  return (
    <div className="flex flex-col md:flex-row p-4 bg-white shadow-md rounded-lg">
      <div className="p-3 border-2 w-full">
        <Photo src={advisor.photo} />
        <Name name={advisor.name} />
        <div className="flex flex-col align-middle md:flex-row">
          <Title title={advisor.title} />
          <span className="hidden md:inline font-bold mb-2 ml-2 mr-2">
            {" | "}
          </span>
          <LicenseNumber license={advisor.license} />
        </div>
        <DirectPhone phone={advisor.directPhone} />
        <CellPhone phone={advisor.cellPhone} />
        <EmailButton email={advisor.email} />
        <CredentialsList credentials={advisor.credentials} />
        <ServicesList services={advisor.services} />
        <AwardsRecognitions awards={advisor.awards} />
        <ClientTestimonials testimonials={advisor.testimonials} />
        <YearsInBusiness years={advisor.yearsInBusiness} />
        <AreasServed areas={advisor.areasServed} />
        <FollowMeLinks links={advisor.followMeLinks} />
      </div>
      {/* summary of advisor */}
      <div className="p-4">
        <Name name={advisor.name} />
        <Title title={advisor.title} />
        <Summary summary={advisor.summary} />
      </div>
    </div>
  );
};

export default SeniorAdvisor;
