// src/components/Validation.tsx
import { validatePasswordStrength } from "./ValPwdStrength.tsx";

export const validateNewPassword = (password: string) => {
  if (!password) {
    return "Please enter a value for New Password";
  } else if (password.length < 8) {
    return "Password must be at least 8 characters";
  } else if (validatePasswordStrength(password) === "Weak") {
    return "Password is not strong enough";
  }
  return "";
};

export const validateConfirmPassword = (
  password: string,
  confirmPassword: string
) => {
  if (!confirmPassword) {
    return "Please confirm your new password";
  } else if (password !== confirmPassword) {
    return "Passwords do not match";
  }
  return "";
};

export const validateForm = (
  formData: any,
  setErrors: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>
) => {
  const newErrors: { [key: string]: string } = {};

  // First Name Validation
  if (!formData.firstName) {
    newErrors.firstName = "First name is required";
  }

  // Last Name Validation
  if (!formData.lastName) {
    newErrors.lastName = "Last name is required";
  }

  // Email Validation
  if (!formData.email) {
    newErrors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    newErrors.email = "Email address is invalid";
  }

  // Confirm Email Validation
  if (formData.email !== formData.confirmEmail) {
    newErrors.confirmEmail = "Emails do not match";
  }

  // Phone Validation
  if (!formData.phone) {
    newErrors.phone = "Please enter a value for Phone number";
  } else if (!/^\d{10}$/.test(formData.phone)) {
    if (!/^\d+$/.test(formData.phone)) {
      newErrors.phone = "Phone number may only contain integers 0-9";
    } else {
      newErrors.phone = "Phone number must be 9 digits";
    }
  }

  // Password Validation
  if (formData.password.length < 8) {
    newErrors.password = "Password must be at least 8 characters";
  }

  // Confirm Password Validation
  if (formData.password !== formData.confirmPassword) {
    newErrors.confirmPassword = "Passwords do not match";
  }

  // Country Validation
  if (!formData.country) {
    newErrors.country = "Please select a value for Country";
  }

  // Opt-in/Opt-out Validation
  if (formData.optIn === undefined) {
    newErrors.optInOut = "Please select an option for email preferences";
  }

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

export const validateAddForm = (
  formData: any,
  setErrors: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>
) => {
  const newErrors: { [key: string]: string } = {};
  if (!formData.addressType)
    newErrors.addressType = "Please select a value for Address Type";
  if (!formData.address1)
    newErrors.address1 = "Please enter a value for Address";
  if (!formData.city) newErrors.city = "Please enter a value for City";
  if (!formData.state)
    newErrors.state = "Please enter a value for State/Province/Region";
  if (!formData.zip) newErrors.zip = "Please enter a value for Zip/Postal Code";
  else if (formData.zip.length < 5)
    newErrors.zip = "Zip/Postal Code must be at least 5 characters";
  if (!formData.country)
    newErrors.country = "Please select a value for Country";
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};
