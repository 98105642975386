import React, { useState } from "react";

interface SideBarMenuProps {
  setActiveComponent: React.Dispatch<React.SetStateAction<string>>;
  activeComponent: string;
}

const SideBarMenu: React.FC<SideBarMenuProps> = ({
  setActiveComponent,
  activeComponent,
}) => {
  const handleLinkClick = (link: string) => {
    setActiveComponent((prevComponent) => (prevComponent === link ? "" : link));
  };

  return (
    <nav className="w-full md:w-64 h-full bg-secondary p-4">
      <ul className="flex flex-col space-y-4">
        <li>
          <a
            href="#personal-details"
            onClick={() => handleLinkClick("personal-details")}
            className={`text-accent hover:underline pl-2 ${
              activeComponent === "personal-details"
                ? "border-l-4 border-red-500"
                : ""
            }`}
          >
            My Personal Details
          </a>
        </li>
        <li>
          <a
            href="#change-password"
            onClick={() => handleLinkClick("change-password")}
            className={`text-accent hover:underline pl-2 ${
              activeComponent === "change-password"
                ? "border-l-4 border-red-500"
                : ""
            }`}
          >
            Change Password
          </a>
        </li>
        <li>
          <a
            href="#addresses"
            onClick={() => handleLinkClick("addresses")}
            className={`text-accent hover:underline pl-2 ${
              activeComponent === "addresses" ? "border-l-4 border-red-500" : ""
            }`}
          >
            Addresses
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default SideBarMenu;
