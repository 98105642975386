import React from "react";

const ServicesList = ({ services }) => (
  <div className="mb-4">
    <h3 className="text-lg font-semibold mb-2">Services</h3>
    <ul className="list-disc list-inside">
      {services.map((service, index) => (
        <li key={index}>{service}</li>
      ))}
    </ul>
  </div>
);

export default ServicesList;
