import React from "react";

interface SeniorAdvisorRegisterFormPageProps {
  register: any;
  handleSubmit: any;
  errors: any;
}

const SeniorAdvisorRegisterFormPage3: React.FC<
  SeniorAdvisorRegisterFormPageProps
> = ({ register, handleSubmit, errors }) => {
  return (
    <>
      <h2 className="text-xl font-semibold mb-4">Define Your Services</h2>

      <div className="mb-4">
        <label className="block text-gray-700">
          <strong>List Your Services:</strong> Clearly outline what you offer,
          from home sale assistance to downsizing and transition support. Show
          how you cater to the unique needs of seniors.
        </label>
        <select
          type="text"
          className="form-input mt-1 block w-full"
          {...register("services", {
            required: "This is required.",
            minLength: { value: 2, message: "Minimum length is 2." },
          })}
        >
          <option value="Certified Negotiation Specialist">
            Certified Negotiation Specialist
          </option>
          <option value="Senior Real Estate Specialist (SRES)">
            Senior Real Estate Specialist (SRES)
          </option>
          <option value="Listing Agent">Listing Agent</option>
          <option value="Buyer's Agent">Buyer's Agent</option>
          <option value="Relocation Specialist">Relocation Specialist</option>
          <option value="Luxury Property Specialist">
            Luxury Property Specialist
          </option>
          <option value="Property Management Specialist">
            Property Management Specialist
          </option>
        </select>
        {errors.services && (
          <p className="text-red-500">{errors.services?.message}</p>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">
          <strong>Areas of Service:</strong> Select the geographical areas where
          you operate. This ensures your services appear to clients in locales
          you serve, optimizing your impact and efficiency.
        </label>
        <select
          type="text"
          className="form-input mt-1 block w-full"
          {...register("areasOfService", {
            required: "This is required.",
            minLength: { value: 2, message: "Minimum length is 2." },
          })}
        >
          <option value="Los Angeles">Los Angeles</option>
          <option value="San Diego">San Diego</option>
          <option value="Santa Barbara">Santa Barbara</option>
        </select>
        {errors.areasOfService && (
          <p className="text-red-500">{errors.areasOfService?.message}</p>
        )}
      </div>
    </>
  );
};
export default SeniorAdvisorRegisterFormPage3;
