export   const validatePasswordStrength = (password: string) => {
    if (password.length < 8) {
      return "Weak";
    } else if (password.length < 12) {
      return "Mediocre";
    } else {
      return "Strong";
    }
  };

export   const getPasswordStrengthColor = (strength: string) => {
    switch (strength) {
      case "Weak":
        return "text-error";
      case "Mediocre":
        return "text-yellow-600";
      case "Strong":
        return "text-green-600";
      default:
        return "";
    }
  };
