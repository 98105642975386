import React from "react";

const EmailButton = ({ email }) => (
  <a
    href={`mailto:${email}`}
    className="block w-2/3 text-center font-semibold text-accent bg-primary hover:bg-secondary p-2 rounded-md mb-4"
  >
    Email
  </a>
);

export default EmailButton;
