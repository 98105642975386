import React from "react";
import { useNavigate } from "react-router-dom";

const Unauthorized: React.FC = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <div className="flex flex-col items-center mt-20">
      <h1 className="text-center text-4xl font-bold">Unauthorized</h1>
      <br />
      <p className="text-center text-lg">You are not authorized to view this page.</p>
      <button 
        onClick={goBack} 
        className="mt-5 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
      >
        Go Home
      </button>
    </div>
  );
};

export default Unauthorized;
