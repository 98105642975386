import React from "react";
import bannerImg from "../assets/banners/banner3.png";
import ourStoryImg from "../assets/ourstoryImg.png";

const OurStory: React.FC = () => {
  return (
    <>
      <div className="w-full overflow-hidden">
        <h1 className="w-1/3 text-4xl text-right font-bold py-4 my-7  border-b-4 border-black">
          Our Story
        </h1>
        {/* <div className="w-full h-1/6 mx-auto ">
          <img
            src={bannerImg}
            alt="Hero"
            className="w-full md:h-banner object-cover"
          />
        </div> */}
      </div>
      <div className="flex mt-7">
        <div className="w-1/3">
          <img src={ourStoryImg} alt="walter img" className="w-full" />
        </div>
        <div className="w-2/3 container mx-auto p-4 md:p-10 border-b-4 border-black mb-10">
          <h2 className="text-3xl font-bold mb-7">
            A Better Way to Transition
          </h2>
          <p>
            At BlueJay®, we've dedicated ourselves to helping older adults
            transition from their homes with ease and compassion. We recognize
            that this journey has often been fragmented, confusing, and fraught
            with fear and discomfort. It doesn't have to be this way. We've
            discovered a better, more efficient approach that minimizes the pain
            and confusion.
          </p>
          <br />
          <p>
            Our journey began with a deeply personal experience. Our founder's
            grandfather, who was a father figure, suffered from dementia.
            Watching him struggle was heart­ wrenching, and Glenn often wished
            he had more control and knowledge to ease his grandfather's path.
            This personal struggle fueled his passion to create a solution to
            honor his memory while supporting others in similar situations.
          </p>
          <br />
          <p>
            Our senior advisors are committed to understanding your unique needs
            and guiding you to the right place. Our support goes beyond finding
            a new home. We're here to navigate the complexities behind the
            scenes-whether it's managing the sale or lease of your property,
            coordinating with trusted vendors, or exploring options like reverse
            mortgages for aging in place.
          </p>
          <br />
          <p>
            We believe in a pressure-free approach. Our mission is to be your
            trusted resource, offering guidance and support as you plan for an
            exciting new chapter. The light at the end of the tunnel isn't an
            oncoming train-it's the start of your promising new journey.
          </p>
          <br />
          <p>
            Our priority is to ensure you have all the information and resources
            you need to make the best decisions for yourself and your family. At
            the end of the day, it's all about what's best for you.
          </p>
        </div>
      </div>
    </>
  );
};

export default OurStory;
