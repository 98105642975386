import React from "react";

const faqData = [
  {
    question: "Do you charge a placement fee?",
    answer: "No.",
  },
  {
    question: "Do you charge the senior community a fee?",
    answer: "No.",
  },
  {
    question: "What services do you provide?",
    answer:
      "We offer comprehensive consultation services including setting up campus tours in senior communities and working closely with their in-house sales representatives. Our team will assist you in developing a broader care plan, addressing how to handle your property-whether it's selling, leasing, or any necessary preparations. We aim to support you throughout the transition process, ensuring that every detail is managed effectively.",
  },
  {
    question: "How do you get compensated for your services?",
    answer:
      "Once the property sells, the Senior Advisor is compensated through escrow if you work together on that part. As your real estate broker, they represent you in the sale transaction. All other services, including planning and coordination, are part of a commitment to you.",
  },
  {
    question: "What if I'm not interested in selling my property?",
    answer:
      "That's perfectly fine. There is no pressure or obligation to sell or lease your property. We're here to be a resource, guiding you to the best community, answering all your questions, and providing support regardless of your decision about your property.",
  },
  {
    question: "Why should I choose your services over another senior advisor?",
    answer:
      "You are free to choose any advisor you prefer. However, by working with us, you can access a network of Senior Advisors who do not charge a fee or receive compensation from the communities they recommend. We are happy to give you the best guidance and support to meet your needs.",
  },
];

const FaqNoAccordion = () => {
  return (
    <>
      {faqData.map((item, index) => (
        <div key={index} className=" container pb-2 border-b border-gray-200">
          <h3 className="w-full text-left py-2 mt-2 text-xxl font-semibold flex justify-between items-center">
            {item.question}
          </h3>
          <p className="py-2 text-xl text-gray-700">{item.answer}</p>
        </div>
      ))}
      ;
      <Disclosure />
    </>
  );
};

export default FaqNoAccordion;

const Disclosure = () => {
  return (
    <div className="container mb-7">
      <h2 className="font-bold text-xl my-4">Senior Advisor Disclosure</h2>
      <div className="text-sm">
        <p>
          We specialize in assisting older adults in finding their ideal senior
          living communities. We want to clarify the expertise and role of our
          Senior Advisors to ensure you have the right expectations when using
          our services. Our Senior Advisors are licensed real estate
          professionals with specialized knowledge in senior real estate. They
          possess certifications focused on assisting older adults and are
          highly knowledgeable about the senior communities within the areas
          they serve.
        </p>
        <br />
        <p>
          <strong>Please note:</strong> While our Senior Advisors are experts in
          real estate, they are not healthcare professionals, psychologists,
          psychiatrists, or licensed caregivers. Their advice and services
          relate strictly to real estate decisions and navigating the options
          within senior community living. We are here to guide you in finding a
          living arrangement that meets your needs, leveraging our expertise in
          real estate within the context of senior living options.
        </p>
      </div>
    </div>
  );
};
