import React from "react";

const Summary = ({ summary }) => (
  <>
    <p className="mb-2">{summary}</p>
    <p className="mb-4">
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestiae
      voluptatum quaerat aliquam doloremque facilis tempore dolore dolorum
      ducimus, iste consequatur quidem rem vel aut eveniet delectus, minus
      perferendis architecto saepe!
    </p>
    <p className="mb-4">
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestiae
      voluptatum quaerat aliquam doloremque facilis tempore dolore dolorum
      ducimus, iste consequatur quidem rem vel aut eveniet delectus, minus
      perferendis architecto saepe!
    </p>
    <p className="mb-4">
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sapiente
      officiis necessitatibus a modi fugiat laudantium maxime dolorum, delectus
      enim doloribus non suscipit minima blanditiis laboriosam inventore
      similique tenetur et ipsa est error recusandae quis? Omnis, ut laborum
      facilis quis minus necessitatibus recusandae dolore repellat qui.
    </p>
  </>
);

export default Summary;
