import React from "react";

interface SubHeroImageProps {
  src: string;
  alt: string;
}

const SubHeroImage: React.FC<SubHeroImageProps> = ({ src, alt }) => (
  <div className="relative">
    <img src={src} alt={alt} className="w-full h-full object-cover" />
  </div>
);

export default SubHeroImage;
