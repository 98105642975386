import React from "react";

interface BannerImgProps {
  imgUrl: string;
}

const BannerImg: React.FC<BannerImgProps> = ({ imgUrl }) => {
  return (
    <div className="banner">
      <img
        src={imgUrl}
        alt="Banner"
        className="w-full h-full object-cover my-10"
      />
    </div>
  );
};
export default BannerImg;
