import React from "react";

const Contact: React.FC = () => {
  return (
    <>
      <div className="w-full overflow-hidden">
        <div className="max-w-screen-3xl mx-auto ">
          <img
            src="https://via.placeholder.com/1920x600"
            alt="Hero"
            className="w-full h-auto"
          />
        </div>
      </div>
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
        <p className="text-lg">
          At Bluejay, we have one objective and one objective only; to help you
          find a great community for you or your loved ones. We offer a host of
          independent and integrated professional options that conform with your
          budget, your timeline, and your objectives.
        </p>
        <h3 className="text-2xl font-semibold mt-3">Customer Service</h3>
        <p className="text-lg mt-3">
          A customer service representative is always available Monday through
          Friday, 6:00am - 5:00pm PST
        </p>
        <p className="text-lg">Phone: 800-987-6543</p>
        <p className="text-lg">Fax: 800-987-6543</p>
        <p className="text-lg">Email: support@bluejay.com</p>
        <p className="text-lg">
          Our mailing address: Bluejay, 21 Jump Street, Beverly Hills, CA 90210
        </p>
      </div>
    </>
  );
};

export default Contact;
