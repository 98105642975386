import React from "react";
import { useNavigate } from "react-router-dom";

const ResetPasswordSuccess: React.FC = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate("/login");
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center text-primary">
          Reset Password
        </h2>
        <p className="text-sm text-center text-secondary">
          Your password has been successfully reset.
        </p>
        <p className="text-sm text-center text-secondary">
          You can now log in with your new password.
        </p>
        <button
          onClick={handleLoginRedirect}
          className="w-full px-4 py-2 font-bold text-white bg-primary rounded-md hover:bg-secondary focus:outline-none focus:ring focus:ring-indigo-200"
        >
          Go to Login
        </button>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;
