import React, { useState } from "react";
import Card from "../components/utils/stepsForServices/Card.tsx";
import img1 from "../assets/freeServices/freeServices-1.jpg";
import img2 from "../assets/freeServices/freeServices-4.png";
import img3 from "../assets/freeServices/freeServices-2.jpg";
import img4 from "../assets/freeServices/freeServices-3.jpg";
import AdvisorsGrid from "../components/advisor/AdvisorsGrid.tsx";
import { useNavigate } from "react-router-dom";

const cardArr = [
  {
    key: 1,
    title: "Initial Meeting",
    img: img1,
    description:
      "The first thing you’ll do with Bluejay® Senior Advisors is schedule a free consultation.",
  },
  {
    key: 2,
    title: "Complimentary Assessments",
    img: img2,
    description:
      "Our team of senior advisors is experienced in conducting comprehensive assessments to evaluate an individual's care needs and connect them with the most appropriate vendors and senior living communities",
  },
  {
    key: 3,
    title: "Free Guided Tours",
    img: img3,
    description:
      "By taking a free guided tour with a senior advisor, seniors can get a firsthand look at different communities and learn about the unique features and amenities that each one offers",
  },
  {
    key: 4,
    title: "Move-in Assistance",
    img: img4,
    description:
      "Bluejay® Senior Advisors can connect you with move-in professionalswho provide tailored support for seniors.",
  },
];

const FreeServices: React.FC = () => {
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState<{
    key: number;
    title: string;
    img: string;
    description: string;
  } | null>(null);

  const handleCardClick = (card: {
    key: number;
    title: string;
    img: string;
    description: string;
  }) => {
    if (!card) return;
    const normalizedDescription =
      "/" + card.title.toLowerCase().replace(/\s+/g, "-");
    console.log("normalizedDescription", normalizedDescription);

    setSelectedCard(card);

    navigate(normalizedDescription);
  };

  return (
    <div>
      <h1 className="text-4xl text-right font-bold w-full md:w-2/5 border-b-4 border-black pl-16 my-7 pb-4">
        Welcome to Our Free Services Page
      </h1>
      <div className="container flex">
        <div className="w-2/3">
          <h2 className="text-3xl font-bold my-3 mb-5">
            <span className="text-9xl font-bold my-3">Free</span> Complimentary
            Services
          </h2>
          <p className="max-w-60">
            We offer all our services at no cost to families in need and we do
            not charge communities or accept money from them. This is all about
            you and what’s best for you.
          </p>
          <p className="max-w-60 mt-4">
            Let us support you through every step of the process with compassion
            and understanding
          </p>
          <AdvisorsGrid initialShowSingleAdvisor={true} />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-screen-md mx-auto border-b-4 border-black mb-10">
          {cardArr &&
            cardArr.map((card) => (
              <Card
                key={card.key}
                title={card.title}
                img={card.img}
                description={card.description}
                onClick={() => handleCardClick(card)}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default FreeServices;
