import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  validatePasswordStrength,
  getPasswordStrengthColor,
} from "../validation/ValPwdStrength.tsx";
import {
  validateNewPassword,
  validateConfirmPassword,
  validateForm,
} from "../validation/Validation.tsx";

interface FormData {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  confirmEmail: string;
  password: string;
  confirmPassword: string;
  country: string;
  optIn: boolean;
}

const CreateAccount = () => {
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
    country: "",
    optIn: true,
  });

  const [passwordStrength, setPasswordStrength] = useState<string>("");
  const [newPasswordError, setNewPasswordError] = useState<string>("");

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm(formData, setErrors)) {
      console.log("Form is valid. Creating account...");
      navigate("/");
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;
    const checked =
      type === "checkbox" ? (e.target as HTMLInputElement).checked : undefined;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });

    // Validate on change
    if (name === "password") {
      const strength = validatePasswordStrength(value);
      setPasswordStrength(strength);
      const error = validateNewPassword(value);
      setNewPasswordError(error);
    }

    validateForm(formData, setErrors);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center text-primary">
          Create Account
        </h2>
        <p className="text-sm text-center text-primary">
          Please fill in the form to create an account.
        </p>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full px-3 py-2 mt-1 border rounded-md"
              required
            />
            {errors.firstName && (
              <p className="text-sm text-error">{errors.firstName}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full px-3 py-2 mt-1 border rounded-md"
              required
            />
            {errors.lastName && (
              <p className="text-sm text-error">{errors.lastName}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Phone
            </label>
            <input
              type="text"
              name="phone"
              placeholder="1 (555) 555-5555"
              value={formData.phone}
              onChange={handleChange}
              className="w-full px-3 py-2 mt-1 border rounded-md"
              required
            />
            {errors.phone && (
              <p className="text-sm text-error">{errors.phone}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 mt-1 border rounded-md"
              required
            />
            {errors.email && (
              <p className="text-sm text-error">{errors.email}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Confirm Email
            </label>
            <input
              type="email"
              name="confirmEmail"
              value={formData.confirmEmail}
              onChange={handleChange}
              className="w-full px-3 py-2 mt-1 border rounded-md"
              required
            />
            {errors.confirmEmail && (
              <p className="text-sm text-error">{errors.confirmEmail}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full px-3 py-2 mt-1 border rounded-md"
              required
            />

            {newPasswordError && (
              <p className="mt-1 text-sm text-error">{newPasswordError}</p>
            )}
            <p
              className={`mt-1 text-sm ${getPasswordStrengthColor(
                passwordStrength
              )}`}
            >
              Password strength: {passwordStrength}
            </p>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Confirm Password
            </label>
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              className="w-full px-3 py-2 mt-1 border rounded-md"
              required
            />
            {errors.confirmPassword && (
              <p className="text-sm text-error">{errors.confirmPassword}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Country
            </label>
            <select
              name="country"
              value={formData.country}
              onChange={handleChange}
              className="w-full px-3 py-2 mt-1 border rounded-md"
              required
            >
              <option value="">Select Country</option>
              <option value="USA">USA</option>
              <option value="Canada">Canada</option>
              <option value="UK">UK</option>
              {/* Add more countries as needed */}
            </select>
            {errors.country && (
              <p className="text-sm text-error">{errors.country}</p>
            )}
          </div>
          <div className="flex items-center">
            <p className="ml-2 text-sm text-gray-700">
              Please opt in for emails so that we can contact you about
              additional resources avabilable to you and keep you up-to-date
              with news from Bluejay. You can unsubscribe any time. Please refer
              to our
              <a href="/privacy-policy" className="text-primary">
                Privacy Policy
              </a>{" "}
              or{" "}
              <a href="/contact" className="text-primary">
                Contact Us
              </a>
              for more details.
            </p>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              name="optInOut"
              value="optIn"
              checked={formData.optIn}
              onChange={handleChange}
              className="w-4 h-4 text-primary border-gray-300 focus:ring-primary"
            />
            <label className="ml-2 text-sm text-gray-700">Opt-in</label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              name="optInOut"
              value="optOut"
              checked={!formData.optIn}
              onChange={handleChange}
              className="w-4 h-4 text-primary border-gray-300 focus:ring-primary"
            />
            <label className="ml-2 text-sm text-gray-700">Opt-out</label>
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2 font-bold text-white bg-primary rounded-md hover:bg-secondary focus:outline-none focus:ring focus:ring-indigo-200"
          >
            Register
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateAccount;
