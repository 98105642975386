import React from "react";

interface CommunityInfoProps {
  name: string;
  address: string;
  phone: string;
  license: string;
}

const CommunityInfo: React.FC<CommunityInfoProps> = ({
  name,
  address,
  phone,
  license,
}) => (
  <div className="space-y-2">
    <h1 className="text-3xl font-bold">{name}</h1>
    <p className="text-lg">{address}</p>
    <p className="text-lg">
      <a href={`tel:${phone}`} className="text-primary font-semibold">
        {phone}
      </a>
    </p>
    <p className="text-sm text-secondary">State License: {license}</p>
  </div>
);

export default CommunityInfo;
