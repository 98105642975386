// SingleAdvisor.js
import React from "react";

const SingleAdvisor = ({ advisor, handlePrevious, handleNext }) => {
  return (
    <div className="flex flex-col items-center justify-center mb-6">
      <img
        src={advisor.imgSrc}
        alt={advisor.name}
        className="rounded-full w-54 h-54 object-cover mb-3"
      />
      <p className="font-semibold text-center">
        {advisor.name} | SRES Senior Advisor
      </p>
      <p className="text-sm text-center text-gray-600">
        {advisor.licenseNumber}
      </p>

      {/* Buttons to navigate advisors */}
      <div className="flex space-x-4 mt-4">
        <button
          onClick={handlePrevious}
          className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400"
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SingleAdvisor;
