import React from "react";

interface SeniorAdvisorRegisterFormPageProps {
  register: any;
  handleSubmit: any;
  errors: any;
}

const SeniorAdvisorRegisterFormPage1: React.FC<
  SeniorAdvisorRegisterFormPageProps
> = ({ register, handleSubmit, errors }) => {
  return (
    <>
      <h2 className="text-xl font-semibold mb-4">Create Your Profile</h2>

      <div className="mb-4">
        <label className="block text-gray-700">First Name:</label>
        <input
          type="text"
          className="form-input mt-1 block w-full"
          {...register("firstName", {
            required: "This is required.",
            minLength: { value: 2, message: "Minimum length is 2." },
          })}
        />
        {errors.firstName && (
          <p className="text-red-500">{errors.firstName?.message}</p>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Last Name:</label>
        <input
          type="text"
          className="form-input mt-1 block w-full"
          {...register("lastName", {
            required: "This is required.",
            minLength: { value: 2, message: "Minimum length is 2." },
          })}
        />
        {errors.lastName && (
          <p className="text-red-500">{errors.lastName?.message}</p>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Password:</label>
        <input
          type="text"
          className="form-input mt-1 block w-full"
          {...register("password", {
            required: "This is required.",
          })}
        />
        {errors.password && (
          <p className="text-red-500">{errors.password?.message}</p>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Confirm Password:</label>
        <input
          type="text"
          className="form-input mt-1 block w-full"
          {...register("confirmPassword", {
            required: "This is required.",
          })}
        />
        {errors.confirmPassword && (
          <p className="text-red-500">{errors.confirmPassword?.message}</p>
        )}
      </div>
    </>
  );
};
export default SeniorAdvisorRegisterFormPage1;
